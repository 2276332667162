import smoothScroll from 'smoothscroll-polyfill';

// needed for smooth animation on:
// Safari 6+
// Internet Explorer 9+
// Microsoft Edge 12+
// Opera Next
smoothScroll.polyfill();

window.process = {
	env: {
		BROWSER: process.env.BROWSER,
		NODE_ENV: process.env.NODE_ENV,
		PLANITY_ENV: process.env.PLANITY_ENV,
		DEBUG_ANALYTICS: process.env.DEBUG_ANALYTICS,
		WIDGET: process.env.WIDGET,
		WHITE_LABEL_WEBSITE: process.env.WHITE_LABEL_WEBSITE,
		USE_HOTJAR: process.env.USE_HOTJAR,
		WELL_BEING: process.env.WELL_BEING
	}
};
